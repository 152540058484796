.btn {
  --background: #507bff;
  --text: #fff;
  --font-size: 16px;
  /* 일정 시간 */
  --duration: 0.44s;
  --move-hover: -4px;
  --shadow: 0 2px 8px -1px rgba(39, 94, 254, 0.32);
  --shadow-hover: 0 4px 20px -2px rgba(39, 94, 254, 0.5);
  --font-shadow: var(--font-size);
  margin-left: 15px;
  padding: 10px 18px;
  font-weight: 500;
  /* 줄 간격 */
  line-height: var(--font-size);
  border-radius: 24px;
  display: block;
  /* border 바깥 외곽선 */
  outline: none;
  /* 텍스트 장식용 선(밑줄) */
  text-decoration: none;
  font-size: var(--font-size);
  /* 자간 */
  letter-spacing: 0.5px;
  background: var(--background);
  color: var(--text);
  box-shadow: var(--shadow);
  transform: translateY(var(--y)) translateZ(0);
  /* 프로퍼티 변경에 따른 표시의 변화를 부드럽게 하기 위해 애니메이션 속도를 조절 */
  transition:
    transform var(--duration) ease,
    box-shadow var(--duration) ease;
  border: 1px solid transparent; /* 테두리 두께 및 스타일 설정 */
}

.btn:hover {
  --y: var(--move-hover);
  --shadow: var(--shadow-hover);
  cursor: pointer;
}

.btn.red {
  --background: #d65d5d;
  --shadow: 0 2px 8px -1px rgba(21, 25, 36, 0.32);
  --shadow-hover: 0 4px 20px -2px rgba(21, 25, 36, 0.5);
}
.btn.green {
  --background: rgb(62, 208, 164);
  --shadow: 0 2prgb (93, 214, 143) 1px rgba(48, 161, 127, 0.32);
  --shadow-hover: 0 4px 20px -2px rgba(48, 161, 127, 0.5);
}

html {
  box-sizing: border-box;
  /* 브라우저에 표현되는 텍스트가 렌더링될 때 부드럽게 표현됨 */
  -webkit-font-smoothing: antialiased;
}
