.category {
  position: absolute;
  top: 20px; /* 조절하여 원하는 위치로 조정 */
  left: 20px; /* 조절하여 원하는 위치로 조정 */
  z-index: 15; /* 다른 요소보다 위에 나타나도록 설정 */
  background-color: white;
  padding: 3px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category li {
  padding: 3px;
  cursor: pointer;
  display: flex;
  margin-bottom: 0px;
}

.category li:hover {
  background-color: #f1f1f1;
}

.menu_selected {
  background-color: #f1f1f1;
}
